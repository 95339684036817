import { api } from '.';
import { PUBLISHER_RESOURCE, TECH_FEE_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 *
 * @param {Publisher} publisher
 * @returns {Promise.<{data, meta}>}
 */
export default async function (publisher, clientId) {
  const partialUrl = api.createUrl({
    [PUBLISHER_RESOURCE]: publisher.id,
    [TECH_FEE_RESOURCE]: undefined,
  });
  const {
    data: { publisherId },
  } = await api.update(partialUrl, publisher.techFeePayload(clientId));
  return new ResponseBuilder(publisherId).build();
}
