var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-left" }, [
    _c(
      "div",
      {
        staticClass: "flex text-gray-800 text-3xl font-bold items-center",
        class: { hidden: _vm.isEditing, block: !_vm.isEditing },
      },
      [
        _vm._t("text"),
        _c(
          "button",
          { staticClass: "edit-icon ml-2 p-1", on: { click: _vm.onEdit } },
          [
            _c("edit-svg", {
              staticClass: "w-4 h-4 text-gray-500 hover:text-gray-700",
            }),
          ],
          1
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass: "inline-flex mt-2 flex items-center",
        class: { hidden: !_vm.isEditing, block: _vm.isEditing },
      },
      [
        _vm._t("input"),
        _c(
          "button",
          { staticClass: "ml-2 p-1", on: { click: _vm.onSave } },
          [
            _c("save-button", {
              staticClass: "text-xs custom-p-1 bg-gray-800 hover:bg-gray-900",
              attrs: { color: "gray" },
            }),
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "p-2 hover:bg-gray-300 rounded-full",
            on: { click: _vm.onCancel },
          },
          [_c("close-svg", { staticClass: "w-3 h-3 text-gray-500" })],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }