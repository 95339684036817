<template>
  <value-edit-layout
    :key="inputKey"
    :is-editing="isEditingDefaultFee"
    @edit="isEditingDefaultFee = true"
    @cancel="onCancel"
    @save="onSave"
  >
    <template #text>
      <p>{{ defaultFee | percentage }}</p>
    </template>
    <template #input>
      <asterix-input
        v-model="defaultFee"
        type="number"
        class="default-fee"
        :min="0"
        :max="100"
        placeholder="5"
        auto-validate
        required="required"
    /></template>
  </value-edit-layout>
</template>

<script>
import { ERRORS } from '@/i18n/forms/errors';
import AsterixInput from '@/components/atoms/AsterixInput';
import ValueEditLayout from '@/components/molecules/shared/ValueEditLayout';

export default {
  name: 'DefaultTechFeeInput',
  components: {
    ValueEditLayout,
    AsterixInput,
  },
  props: {
    value: {
      type: Number,
      default: () => null,
    },
  },
  data: () => ({
    defaultFee: 0,
    isEditingDefaultFee: false,
    previousValue: 0,
    inputKey: 0,
  }),
  computed: {
    defaultFeeError() {
      if (this.defaultFee < 0) {
        return ERRORS.en.min(0);
      }
      if (this.defaultFee > 100) {
        return ERRORS.en.max(0);
      }
      if (this.defaultFee === '') {
        return ERRORS.en.required;
      }
      return null;
    },
  },
  watch: {
    value(newVal) {
      this.defaultFee = newVal;
      this.previousValue = newVal;
    },
  },
  created() {
    this.defaultFee = this.value;
    this.previousValue = this.value;
  },
  methods: {
    onSave() {
      if (!this.defaultFeeError) {
        this.isEditingDefaultFee = false;
        this.$emit('save', this.defaultFee);
      }
    },
    onCancel() {
      this.isEditingDefaultFee = false;
      this.defaultFee = this.previousValue;
      // TODO: input error is only checked on blur, so when value is changed, the error validation doesn't refresh the error state
      this.inputKey++;
    },
  },
};
</script>

<style scoped>
.default-fee {
  min-width: 12rem;
}
</style>
