import { api } from '.';
import { PUBLISHER_RESOURCE, TECH_FEE_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = publisherId => ({
  [PUBLISHER_RESOURCE]: publisherId,
  [TECH_FEE_RESOURCE]: undefined,
});

/**
 *
 * @param {Publisher} publisher
 * @returns {Promise.<{data, meta}>}
 */
export async function deletePublisherTechFee(publisher) {
  const partialUrl = api.createUrl(getResources(publisher.id));
  const {
    data: { publisherId },
  } = await api.delete(partialUrl);
  return new ResponseBuilder(publisherId).build();
}
