import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig.js';
import Column from '@/model/shared/Column';

export default {
  filters: [FILTERS_CONFIG.PUBLISHER_NAME],
  headers: [
    new Column('PUBLISHER', 'name', true, true).setClass('font-bold truncate'),
    new Column('FEE VALUE', 'techFee', true, true).toRight(),
    new Column('ACTIONS', 'actions', true, false, [], 'text-center w-4'),
  ],
};
