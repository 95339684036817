<template>
  <div class="text-left">
    <div class="flex text-gray-800 text-3xl font-bold items-center" :class="{ hidden: isEditing, block: !isEditing }">
      <slot name="text" />
      <button class="edit-icon ml-2 p-1" @click="onEdit">
        <edit-svg class="w-4 h-4 text-gray-500 hover:text-gray-700" />
      </button>
    </div>
    <div class="inline-flex mt-2 flex items-center" :class="{ hidden: !isEditing, block: isEditing }">
      <slot name="input" />

      <button class="ml-2 p-1" @click="onSave">
        <save-button color="gray" class="text-xs custom-p-1 bg-gray-800 hover:bg-gray-900"></save-button>
      </button>
      <button class="p-2 hover:bg-gray-300 rounded-full" @click="onCancel">
        <close-svg class="w-3 h-3 text-gray-500" />
      </button>
    </div>
  </div>
</template>

<script>
import CloseSvg from '@/components/icons/CloseSvg';
import EditSvg from '@/components/icons/EditSvg';
import SaveButton from '@/components/atoms/SaveButton';

export default {
  name: 'ValueEditLayout',
  components: {
    SaveButton,
    CloseSvg,
    EditSvg,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onEdit() {
      this.$emit('edit');
    },
    onSave() {
      this.$emit('save');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
