var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("value-edit-layout", {
    key: _vm.inputKey,
    attrs: { "is-editing": _vm.isEditingDefaultFee },
    on: {
      edit: function ($event) {
        _vm.isEditingDefaultFee = true
      },
      cancel: _vm.onCancel,
      save: _vm.onSave,
    },
    scopedSlots: _vm._u([
      {
        key: "text",
        fn: function () {
          return [
            _c("p", [_vm._v(_vm._s(_vm._f("percentage")(_vm.defaultFee)))]),
          ]
        },
        proxy: true,
      },
      {
        key: "input",
        fn: function () {
          return [
            _c("asterix-input", {
              staticClass: "default-fee",
              attrs: {
                type: "number",
                min: 0,
                max: 100,
                placeholder: "5",
                "auto-validate": "",
                required: "required",
              },
              model: {
                value: _vm.defaultFee,
                callback: function ($$v) {
                  _vm.defaultFee = $$v
                },
                expression: "defaultFee",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }