var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "router-link",
                  { attrs: { to: _vm.newPublisherRoute } },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass:
                          "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                        attrs: { variant: "pill", color: "gray" },
                      },
                      [_vm._v(" + New Publisher ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.filtersSetFiltersFind,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "min-w-max" },
                              [
                                _c("sun-search-input", {
                                  attrs: {
                                    "class-input": "text-sm",
                                    value: _vm.filterQuick.name,
                                  },
                                  on: { search: _vm.onQuickFiltersSearch },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.status`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24 cursor-pointer",
                              attrs: {
                                options: filter.options,
                                "close-on-select": "",
                                "disable-selected-options": "",
                                "track-by": "value",
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.category.id`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24 cursor-pointer",
                              attrs: {
                                options: _vm.categoriesList,
                                "close-on-select": "",
                                "disable-selected-options": "",
                                "track-by": "id",
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.subcategory.id`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24 cursor-pointer",
                              attrs: {
                                options: _vm.categoriesList,
                                "close-on-select": "",
                                "disable-selected-options": "",
                                "track-by": "id",
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                "group-label": "name",
                                "group-values": "subcategories",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "mt-2 w-full",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    striped: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.name`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: { title: item.name },
                              },
                              [
                                _c("asterix-column-router-link", {
                                  attrs: {
                                    item: item,
                                    route: _vm.publisherEdit,
                                    "param-value": "id",
                                    "param-name": "publisherId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.status`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                _c("status-pill", {
                                  attrs: {
                                    status: item.status.value,
                                    color: item.status.color,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.commission`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(" " + _vm._s(item.commissionValue) + "% "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.category.name`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.category.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.subcategory.name`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.subcategory.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.postBackUrl`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex justify-start cursor-pointer items-center truncate",
                                  on: {
                                    click: function ($event) {
                                      return _vm.copyText(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "sun-popover",
                                    {
                                      attrs: {
                                        "text-info":
                                          _vm.showClipboardText(item),
                                        position: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.postBackUrl) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                    "options-per-page": _vm.availablePageSizes,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.showModal },
        on: { cancel: _vm.closeDeleteModal, confirm: _vm.deleteElement },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteModalDescription))]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }