import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';
import Column from '@/model/shared/Column';

export default Object.freeze({
  headers: [
    new Column('NAME', 'name').asSort().setClass('font-bold truncate'),
    new Column('STATUS', 'status').asSort(),
    new Column('OUR COMMISSION', 'commission').toRight(),
    new Column('CATEGORY', 'category.name').asSort(),
    new Column('SUBCATEGORY', 'subcategory.name').asSort(),
    new Column('POSTBACK URL', 'postBackUrl'),
    new Column('ACTIONS', 'actions').setClass('w-4'),
  ],
  filters: [
    FILTERS_CONFIG.PUBLISHER_NAME,
    FILTERS_CONFIG.PUBLISHER_STATUS,
    FILTERS_CONFIG.CATEGORY_ID,
    FILTERS_CONFIG.SUB_CATEGORY_ID,
  ],
});
