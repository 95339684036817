/// <reference path="../../../../entities/ecommerce/EcommerceClient.js" />

import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';

/**
 *
 * @param {EcommerceClient} client
 * @returns {Promise.<object>}
 */
export async function updateClient(client) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: client.id,
  });
  const { data } = await api.create(partialUrl, client.payload());
  return data;
}
