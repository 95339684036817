<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <sun-button
          variant="pill"
          class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800"
          color="gray"
          @click="showTechFeeModal = true"
        >
          + New Custom Tech Fee
        </sun-button>
      </template>

      <template #before-status>
        <article class="p-6 bg-white rounded mb-8">
          <header class="uppercase font-bold text-gray-500 text-left text-xs">
            <h1>Default tech fee (%)</h1>
          </header>

          <default-tech-fee-input v-if="ecommerceClient" :value="ecommerceClient.techFee" @save="onDefaultFeeSave" />
          <default-tech-fee-input-loader v-else />
        </article>
      </template>
      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick.name" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          striped
          :loading="isLoading"
          class="mt-2 w-full"
          @sort="onSortTable"
        >
          <template #[`col.name`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.name }} <status-pill class="ml-2 flex" :status="item.status.value" :color="item.status.color" />
            </sun-data-table-cell>
          </template>
          <template #[`col.techFee`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.techFee | percentage }}
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data
              class="bg-white"
              title="No custom tech fees set"
              subtitle="All the publishers have the default tech fee set"
            />
          </template>
        </sun-data-table>

        <sun-pagination-page
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          :options-per-page="availablePageSizes"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>
    <delete-modal
      :open="publisherToRemove ? true : false"
      @cancel="publisherToRemove = null"
      @confirm="onPublisherFeeDelete"
    >
      <template #description>{{ deleteModalDescription }}</template>
    </delete-modal>

    <publisher-tech-fee-modal
      v-if="showTechFeeModal"
      :value="publisherToEdit"
      @update="onUpdatePublisher"
      @cancel="closeModal"
    ></publisher-tech-fee-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import CONFIG from './config';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { deletePublisherTechFee, getPublishersTechFee } from '@/services/modules/ecommerce/publisherTechFee';
import updatePublisherTechFee from '@/services/modules/ecommerce/publisherTechFee/updatePublisherTechFee';
import { ERRORS } from '@/i18n/forms/errors';
import DefaultTechFeeInput from '@/components/molecules/modules/ecommerce/DefaultTechFeeInput';
import { getClientById, updateClient } from '@/services/modules/ecommerce/client';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import DefaultTechFeeInputLoader from '@/components/atoms/Loaders/DefaultTechFeeInputLoader';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'PublisherTechFeeList',
  components: {
    DefaultTechFeeInputLoader,
    DefaultTechFeeInput,
    AsterixSection,
    PublisherTechFeeModal: () => import('@/components/organisms/modules/ecommerce/PublisherTechFeeModal'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    DeleteModal,
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { name: undefined },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    items: [],
    actions: [{ name: 'Edit' }, { name: 'Delete' }],
    publisherToRemove: null,
    publisherToEdit: null,
    ecommerceClient: null,
    ERRORS,
    isEditingDefaultFee: false,
    showTechFeeModal: false,
    availablePageSizes: [20, 50, 100],
  }),
  computed: {
    ...mapGetters({
      contextActive: ACTIVE_CONTEXT,
      activeClient: ACTIVE_CLIENT,
    }),
    defaultFeeError() {
      let error = ERRORS.en.required;
      if (this.ecommerceClient.techFee < 0) {
        error = ERRORS.en.min(0);
      } else if (this.ecommerceClient.techFee > 100) {
        error = ERRORS.en.max(0);
      }

      return error;
    },
    deleteModalDescription() {
      return `You are going to set ${this.publisherToRemove.name} fee to default value. Do you want to continue?`;
    },
  },
  async created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];

    await this.updateClient(); // Is it necessary ?
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),

    async onActiveClientChange() {
      this.resetCache();
      this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
      await this.resetPageOnQueryParams();
      await this.filtersOnActiveClientChange();

      await Promise.all([this.getTableItems(), this.updateClient()]);
    },
    async onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.publisherToEdit = item;
          this.showTechFeeModal = true;
          break;
        case 'Delete':
          this.publisherToRemove = item;
          break;
      }
    },
    async updateClient() {
      const { data: ecommerceClient } = await getClientById(this.activeClient.id);
      this.ecommerceClient = ecommerceClient;
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getPublishersTechFee);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },

    async onDefaultFeeSave(newFee) {
      if (this.ecommerceClient) {
        this.ecommerceClient.techFee = newFee;
        try {
          await updateClient(this.ecommerceClient);
          this.createToast(Toast.success('Success!', 'Default fee updated'));
        } catch (e) {
          this.createToast(Toast.error('Error!', 'Default fee could not be updated'));
        }
      }
    },

    async onPublisherFeeDelete() {
      try {
        await deletePublisherTechFee(this.publisherToRemove);
        this.deleteFromCacheById(this.publisherToRemove.id);

        this.createToast(Toast.success('Success!', `${this.publisherToRemove.name} custom tech fee removed`));
        await this.getTableItems();
      } catch (e) {
        this.createToast(Toast.error('Error!', 'Try again later'));
      } finally {
        this.publisherToRemove = null;
      }
    },

    async onUpdatePublisher(publisher) {
      try {
        await updatePublisherTechFee(publisher, this.activeClient.id);
        this.createToast(Toast.success('Success!', `${publisher.name} fee set to ${publisher.techFee}%`));
        await this.getTableItems();
      } catch (e) {
        this.createToast(Toast.error('Error!', `${publisher.name} fee could not be updated`));
      } finally {
        this.closeModal();
      }
    },
    closeModal() {
      this.showTechFeeModal = false;
      this.publisherToEdit = null;
    },
  },
};
</script>
<style scoped>
.fee .edit-icon {
  @apply hidden;
}
.fee:hover .edit-icon {
  @apply block;
}
</style>
