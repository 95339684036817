import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';

/**
 * @param {EcommerceClient} client
 * @returns {Promise.<object>}
 */
export function deleteClient(client) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: client.id,
  });
  return api.delete(partialUrl);
}
