/**
 * Copy te input value to clipboard
 * @param {String} text
 * @returns {String} Copied text
 */
export const copyToClipboard = text => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  return text;
};
