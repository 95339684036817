<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <router-link :to="newPublisherRoute">
          <sun-button variant="pill" class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800" color="gray">
            + New Publisher
          </sun-button>
        </router-link>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick.name" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.status`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="filter.options"
              close-on-select
              disable-selected-options
              track-by="value"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24 cursor-pointer"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.category.id`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="categoriesList"
              close-on-select
              disable-selected-options
              track-by="id"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24 cursor-pointer"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.subcategory.id`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="categoriesList"
              close-on-select
              disable-selected-options
              track-by="id"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24 cursor-pointer"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              group-label="name"
              group-values="subcategories"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          striped
          :loading="isLoading"
          class="mt-2 w-full"
          @sort="onSortTable"
        >
          <template #[`col.name`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass" :title="item.name">
              <asterix-column-router-link
                :item="item"
                :route="publisherEdit"
                param-value="id"
                param-name="publisherId"
              />
            </sun-data-table-cell>
          </template>
          <template #[`col.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill :status="item.status.value" :color="item.status.color" />
            </sun-data-table-cell>
          </template>

          <template #[`col.commission`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass"> {{ item.commissionValue }}% </sun-data-table-cell>
          </template>

          <template #[`col.category.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.category.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.subcategory.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.subcategory.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.postBackUrl`]="{ item }">
            <sun-data-table-cell>
              <div class="flex justify-start cursor-pointer items-center truncate" @click="copyText(item)">
                <sun-popover :text-info="showClipboardText(item)" position="top">
                  {{ item.postBackUrl }}
                </sun-popover>
              </div>
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          :options-per-page="availablePageSizes"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>
    <delete-modal :open="showModal" @cancel="closeDeleteModal" @confirm="deleteElement">
      <template #description>{{ deleteModalDescription }}</template>
    </delete-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CATEGORIES, GET_CATEGORIES_REQUEST } from '@/store/modules/category/keys';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { getPublishers } from '@/services/modules/ecommerce/publisher';
import AsterixSection from '@/components/templates/AsterixSection';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { create as newPublisherRoute } from '@/router/private/modules/ecommerce/supply/publisher/create';
import { PUBLISHER_RESOURCE } from '@/services/keys';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { deepClone } from '@/utils/deepClone';
import CONFIG from './config';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import AsterixColumnRouterLink from '@/components/atoms/AsterixColumnRouterLink';
import { edit as publisherEdit } from '@/router/private/modules/ecommerce/supply/publisher/edit';

export default {
  name: 'PublisherList',
  components: {
    AsterixSection,
    AsterixColumnRouterLink,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    DeleteModal,
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { name: undefined },
    }),
    indexMixin,
    deleteModalMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    items: [],
    currentApi: new EcommerceApi(),
    actions: [{ name: 'Edit' }, { name: 'Delete' }],
    clipboardText: '',
    newPublisherRoute,
    deleteModalDescription: '',
    resource: PUBLISHER_RESOURCE,
    publisherEdit,
    availablePageSizes: [20, 50, 100],
  }),
  computed: {
    ...mapGetters({
      contextActive: ACTIVE_CONTEXT,
      categories: CATEGORIES,
    }),
    categoriesList() {
      return deepClone(this.categories);
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
    this.getCategoriesAndSubcategories();
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({ getCategoriesAndSubcategories: GET_CATEGORIES_REQUEST }),
    // TODO delete from here and index, only waiting delete from indexMixin

    onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.routeToEditPublisher(item);
          break;
        case 'Delete':
          this.routeToDeletePublisher(item);
          break;
      }
    },
    routeToDeletePublisher(item) {
      this.deleteModalDescription = `Publisher "${item.name}" is going to be deleted.`;
      this.showModal = true;
      this.selectedId = item.id;
      this.openModal({ id: item.id });
    },
    routeToEditPublisher(item) {
      this.$router.push({ name: publisherEdit.name, params: { publisherId: item.id } });
    },
    showClipboardText(item) {
      if (item.postBackUrl !== this.clipboardText) {
        return 'Copy to clipboard';
      }
      return 'Copied';
    },
    closeDeleteModal() {
      this.selectedId = null;
      this.showModal = false;
    },
    deleteElement() {
      this.deleteElementById();
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getPublishers);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    copyText(item) {
      this.clipboardText = copyToClipboard(item.postBackUrl);
    },
  },
};
</script>
