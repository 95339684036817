<template>
  <basic-loader class="h-6 block w-40 rounded mt-2" />
</template>

<script>
import BasicLoader from '@/components/atoms/Loaders/BasicLoader';

export default {
  name: 'DefaultTechFeeInputLoader',
  components: { BasicLoader },
};
</script>
