var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "sun-button",
                  {
                    staticClass:
                      "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                    attrs: { variant: "pill", color: "gray" },
                    on: {
                      click: function ($event) {
                        _vm.showTechFeeModal = true
                      },
                    },
                  },
                  [_vm._v(" + New Custom Tech Fee ")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "before-status",
            fn: function () {
              return [
                _c(
                  "article",
                  { staticClass: "p-6 bg-white rounded mb-8" },
                  [
                    _c(
                      "header",
                      {
                        staticClass:
                          "uppercase font-bold text-gray-500 text-left text-xs",
                      },
                      [_c("h1", [_vm._v("Default tech fee (%)")])]
                    ),
                    _vm.ecommerceClient
                      ? _c("default-tech-fee-input", {
                          attrs: { value: _vm.ecommerceClient.techFee },
                          on: { save: _vm.onDefaultFeeSave },
                        })
                      : _c("default-tech-fee-input-loader"),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.filtersSetFiltersFind,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "min-w-max" },
                              [
                                _c("sun-search-input", {
                                  attrs: {
                                    "class-input": "text-sm",
                                    value: _vm.filterQuick.name,
                                  },
                                  on: { search: _vm.onQuickFiltersSearch },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "mt-2 w-full",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    striped: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.name`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _vm._v(" " + _vm._s(item.name) + " "),
                                _c("status-pill", {
                                  staticClass: "ml-2 flex",
                                  attrs: {
                                    status: item.status.value,
                                    color: item.status.color,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.techFee`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("percentage")(item.techFee)) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", {
                              staticClass: "bg-white",
                              attrs: {
                                title: "No custom tech fees set",
                                subtitle:
                                  "All the publishers have the default tech fee set",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                    "options-per-page": _vm.availablePageSizes,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.publisherToRemove ? true : false },
        on: {
          cancel: function ($event) {
            _vm.publisherToRemove = null
          },
          confirm: _vm.onPublisherFeeDelete,
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteModalDescription))]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.showTechFeeModal
        ? _c("publisher-tech-fee-modal", {
            attrs: { value: _vm.publisherToEdit },
            on: { update: _vm.onUpdatePublisher, cancel: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }