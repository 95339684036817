import { api } from '.';
import { CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import EcommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise<{data:EcommerceEntityParser,meta:EndpointMeta}>}
 */
export async function getClientById(clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
  });

  params.addInclude(['currency', 'linkedUsers']);

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(EcommerceEntityParser.parse(data), data.meta).build();
}
